<template>
    <div v-if="$can('read','expense')">
        <app-collapse class="mt-1 mb-1" style="border-radius: 150px !important">
            <app-collapse-item title="Filter">
                <b-card>
                    <b-row>
                        
                        <b-col md="3">
                            <b-form-group
                                label="Select Account"
                                label-for="select-account">
                                <v-select
                                    v-model="searchFilters.accountSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="accountsList"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="Select Business Unit"
                                label-for="select-business-unit">
                                <v-select
                                    v-model="searchFilters.businessUnitSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="businessUnitList"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="Select Category"
                                label-for="select-category">
                                <v-select
                                    v-model="searchFilters.categorySelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="expenseCategoryList"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3" v-if="filteredSubCategories.length > 0">
                            <b-form-group
                                label="Select Sub-Category"
                                label-for="select-sub-category">
                                <v-select
                                    v-model="searchFilters.subCategorySelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="filteredSubCategories"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="Select Payment Mode"
                                label-for="select-payment-mode">
                                <v-select
                                    v-model="searchFilters.paymentModeSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="paymentModeList"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="Select Location"
                                label-for="select-location">
                                <v-select
                                    v-model="searchFilters.locationSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="locationList"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                        
                        <b-col md="3">
                            <b-form-group
                                label="Select GST/TDS Invoice"
                                label-for="select-gst-tds-invoice">
                                <v-select
                                    v-model="searchFilters.gstTDSSelected"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="title"
                                    :options="gstTDSOptions"
                                    :reduce="(option) => option.title"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>    
                        <b-col md="3">
                            <b-form-group label="Date Filters">
                                <b-form-radio-group
                                    v-model="searchFilters.dateFieldSelected"
                                    :options="dateFieldOptions"
                                    class="demo-inline-spacing"
                                    name="radio-inline"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="Start Date"
                                label-for="start-date"
                            >
                                <flat-pickr
                                    v-model="searchFilters.startDate"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                                label="End Date"
                                label-for="end-date"
                            >
                                <flat-pickr
                                    v-model="searchFilters.endDate"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-button class="mt-2" variant="primary"  
                                @click="getExpensesList()" 
                            >Filter</b-button>
                            <span class="px-1 mt-1" @click="clearFilter()">Clear Filter</span>
                
                        </b-col>
                    </b-row>
                </b-card>
            </app-collapse-item>
        </app-collapse>                    
        <b-card
            no-body
            class="card-statistics"
        >
            <b-card-header>
                <b-card-title>Statistics</b-card-title>
                <span v-if="error"> {{ error }}</span>
            </b-card-header>
            <b-card-body class="statistics-body">
                <b-row>
                <b-col
                    md="3"
                >
                    <b-media no-body>
                        <b-media-aside
                            class="mr-2"
                        >
                            <b-avatar
                            size="48"
                            variant="light-primary"
                            >
                                <feather-icon
                                    size="24"
                                    icon="UsersIcon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ expense.totalAmount.toFixed(2) || 0 }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                            Total Amount
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col
                    md="3"
                >
                <b-media no-body>
                        <b-media-aside
                            class="mr-2"
                        >
                            <b-avatar
                            size="48"
                            variant="light-success"
                            >
                                <feather-icon
                                    size="24"
                                    icon="UsersIcon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ expense.totalGST.toFixed(2) || 0}} 
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                GST Amount
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col
                    md="3"
                >
                    <b-media no-body>
                        <b-media-aside
                            class="mr-2"
                        >
                            <b-avatar
                            size="48"
                            variant="light-warning"
                            >
                                <feather-icon
                                    size="24"
                                    icon="UsersIcon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ expense.totalTDS.toFixed(2) || 0}} 
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                            TDS Amount
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                <b-col
                    md="3"
                >
                <b-media no-body>
                        <b-media-aside
                            class="mr-2"
                        >
                            <b-avatar
                            size="48"
                            variant="light-info"
                            >
                                <feather-icon
                                    size="24"
                                    icon="UsersIcon"
                                />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <h4 class="font-weight-bolder mb-0">
                                {{ expense.pendingApproval || 0 }} 
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                            Pending Approval
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
                
                </b-row>
            </b-card-body>
        </b-card>
        
        <app-collapse class="mt-1 mb-1 text-success" style="border-radius: 150px !important" v-if="$can('manage','expense')">
            <app-collapse-item title="Pending Approvals">
                <b-table class="font-weight-bolder"
                    striped
                    hover
                    responsive
                    :per-page="perPage"
                    :current-page="currentPage"
                    :items="expenseListPendingApproval"
                    :fields="approvalFields">
                    <template #cell(id)="data" >
                        <div class="text-nowrap text-success" >
                            {{ data.item.id }} 
                        </div>
                    </template>
                    <template #cell(item)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.item }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.invoice_no" >
                            {{ data.item.invoice_no }} 
                        </div>
                    </template>
                    <template #cell(account)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.account }} 
                        </div>
                        <div class="text-nowrap" >
                            {{ data.item.business_unit }} 
                        </div>
                        <div class="text-nowrap" >
                            {{ data.item.location }} 
                        </div>
                    </template>
                    <template #cell(category)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.category }} 
                        </div>
                        <div class="text-nowrap" >
                            {{ data.item.sub_category }} 
                        </div>
                    </template>
                    <template #cell(expense_date)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.expense_date }} 
                        </div>
                    </template>
                    <template #cell(amount)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.amount }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.is_gst">
                            GST: {{ data.item.gst_amount }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.is_tds">
                            TDS: {{ data.item.tds_amount }} 
                        </div>
                    </template>
                    <template #cell(payment_mode)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.payment_mode }} 
                        </div>
                    </template>
                    <template #cell(is_gst)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.is_gst === true ? 'Yes' : 'No' }} 
                        </div>
                    </template>
                    <template #cell(is_tds)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.is_tds === true ? 'Yes' : 'No' }} 
                        </div>
                    </template>
                    <template #cell(due_date)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.due_date }} 
                        </div>
                    </template>
                    <template #cell(comment)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.comment }} 
                        </div>
                    </template>
                    <template #cell(added_by)="data">
                        <div class="text-nowrap" >
                            {{ JSON.parse(data.item.added_by).title }} 
                        </div>
                    </template>
                    <template #cell(approved_by)="data">
                        <div class="text-nowrap" v-if="data.item.approved_by">
                            {{ JSON.parse(data.item.approved_by).title }} 
                        </div>
                    </template>
                    <template #cell(actions)="data">  
                        <div class="demo-inline-spacing">
                        <span @click="approveExpense(data.item.id)" v-b-tooltip.hover.bottom="'Approve'" ><feather-icon size="21" icon="CheckSquareIcon" class="text-primary"/></span>
                        <span @click="rejectExpense(data.item.id)" v-b-tooltip.hover.bottom="'Reject'" ><feather-icon size="21" icon="XSquareIcon" class="text-danger"/></span>
                        </div>
                    </template>
                </b-table>
            </app-collapse-item>
        </app-collapse>
            
            
        <div class="text-right" v-if="$can('create','expense')"> 
          <router-link :to="'/apps/add-expense'" >
            <button type="button" class="btn btn-success mb-1 mr-1">Create Expense</button>
          </router-link>
        </div>
        <b-card>
            <b-row>
                <b-col md="4">
                    <b-form-group
                        class="mb-0"
                    >
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select
                            id="perPageSelect"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            class="w-50"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">Total Expenses Raised : {{ totalRows }}</b-col>
                <b-col md="4">
                    <b-form-group
                        label="Filter"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="searchFilters.filter"
                            type="search"
                            placeholder="Type to Search"
                            trim
                        />
                        <b-input-group-append>
                            <b-button
                            
                                :disabled="!searchFilters.filter"
                                @click="searchFilters.filter = ''"
                            >
                            Clear
                            </b-button>
                        </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <h4 class="text-primary text-center"> Expense List </h4>
            <b-table class="font-weight-bolder"
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="expenseList"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="searchFilters.filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  
                  style="font-size: 0.8rem; font-weight: 400; line-height: 1.3;"
                >
                    <template #cell(id)="data" >
                        <div class="text-nowrap text-success" >
                            {{ data.item.id }} 
                        </div>
                    </template>
                    <template #cell(item)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.item }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.invoice_no" >
                            {{ data.item.invoice_no }} 
                        </div>
                    </template>
                    <template #cell(account)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.account }} 
                        </div>
                        <div class="text-nowrap" >
                            {{ data.item.business_unit }} 
                        </div>
                        <div class="text-nowrap" >
                            {{ data.item.location }} 
                        </div>
                    </template>
                    <template #cell(category)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.category }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.sub_category">
                            {{ data.item.sub_category }} 
                        </div>
                    </template>
                    <template #cell(expense_date)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.expense_date }} 
                        </div>
                    </template>
                    <template #cell(amount)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.amount }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.is_gst">
                            GST: {{ data.item.gst_amount }} 
                        </div>
                        <div class="text-nowrap" v-if="data.item.is_tds">
                            TDS: {{ data.item.tds_amount }} 
                        </div>
                    </template>
                    <template #cell(payment_mode)="data" >
                        <div class="text-nowrap" >
                            {{ data.item.payment_mode }} 
                        </div>
                       
                    </template>
                    <template #cell(added_by)="data">
                        <div class="text-nowrap" >
                            {{ JSON.parse(data.item.added_by).title }} 
                        </div>
                       
                    </template>
                    <template #cell(approved_by)="data">
                        <div class="text-nowrap" v-if="data.item.approved_by">
                            {{ JSON.parse(data.item.approved_by).title }} 
                        </div>
                       
                    </template>
                    <template #cell(actions)="data">  
                        <div class="demo-inline-spacing" v-if="$can('update','expense')">
                            <router-link :to="{ name: 'apps-edit-expense', params: { id: data.item.id}}">
                                <span v-b-tooltip.hover.bottom="'Edit Expense'" ><feather-icon size="21" icon="EditIcon" class="text-info"/></span>
                            </router-link>
                        </div>
                    </template>
                
            </b-table>
            <b-row>
                <b-col
                    cols="12"
                >
                    <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="center"
                    size="sm"
                    class="my-0"
                    />
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardText, BCardTitle, BCardBody, BMedia, BMediaAside, BMediaBody, BButton, BSpinner, BTable, BBadge, BPagination, BAvatar, VBTooltip, BInputGroup, BFormInput, BFormGroup, BInputGroupAppend, BFormSelect, BFormRadioGroup } from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import expenseService from '@/services/expense-service'
import AdminConfigService from '@/services/admin-config-service'
import clientService from '@/services/client-service'
import locationService from '@/services/location-service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
    data() {
      return {
        fields: [
            { key: 'id', label: 'ID', sortable: true },
            { key: 'item', label: 'Item', sortable: true },
            { key: 'account', label: 'Account', sortable: true },
            // { key: 'business_unit', label: 'Business Unit', sortable: true },
            { key: 'category', label: 'Category', sortable: true },
            // { key: 'sub_category', label: 'Sub Category', sortable: true },
            { key: 'expense_date', label: 'Expense Date', sortable: true },
            { key: 'amount', label: 'Amount', sortable: true },
            { key: 'payment_mode', label: 'Payment Mode', sortable: true },
            // { key: 'is_gst', label: 'GST Applicable', sortable: true },
            // { key: 'gst_amount', label: 'GST Amount', sortable: true },
            // { key: 'is_tds', label: 'TDS Applicable', sortable: true },
            // { key: 'tds_amount', label: 'TDS Amount', sortable: true },
            { key: 'due_date', label: 'Due Date', sortable: true },
            // { key: 'location', label: 'Location', sortable: true },
            // { key: 'comment', label: 'Comment' }, // Might not need sorting for a comment
            { key: 'is_approved', label: 'Approved', sortable: true },
            { key: 'added_by', label: 'Added By', sortable: true },
            { key: 'approved_by', label: 'Approved By', sortable: true },
            { key: "actions", label: 'Actions'},
        ],
        approvalFields: [
            { key: 'id', label: 'ID', sortable: true },
            { key: 'item', label: 'Item', sortable: true },
            { key: 'account', label: 'Account', sortable: true },
            // { key: 'business_unit', label: 'Business Unit', sortable: true },
            // { key: 'invoice_no', label: 'Invoice No', sortable: true },
            { key: 'category', label: 'Category', sortable: true },
            // { key: 'sub_category', label: 'Sub Category', sortable: true },
            { key: 'expense_date', label: 'Expense Date', sortable: true },
            { key: 'amount', label: 'Amount', sortable: true },
            { key: 'payment_mode', label: 'Payment Mode', sortable: true },
            { key: 'is_gst', label: 'GST Applicable', sortable: true },
            // { key: 'gst_amount', label: 'GST Amount', sortable: true },
            { key: 'is_tds', label: 'TDS Applicable', sortable: true },
            // { key: 'tds_amount', label: 'TDS Amount', sortable: true },
            { key: 'due_date', label: 'Due Date', sortable: true },
            // { key: 'location', label: 'Location', sortable: true },
            { key: 'comment', label: 'Comment' }, // Might not need sorting for a comment
            { key: 'is_approved', label: 'Approved', sortable: true },
            { key: 'added_by', label: 'Added By', sortable: true },
            { key: 'approved_by', label: 'Approved By', sortable: true },
            { key: "actions", label: 'Actions'},
            // { key: "invoic_no", label: 'Invoice N'},
        ],
        pageOptions: [10,20, 30, 50, 100, 200],
        totalRows: 0,
        perPage: 50,
        currentPage: 1,
        // filter: null,
        sortBy: '',
        sortDesc: '',
        sortDirection: 'desc',
        expenseList: [],
        expense: {},
        error: null,
        accessCheck: false,
        expenseListPendingApproval: [],
        allUsers: [],
        role: null,
        name: null,
        loggedInUser: null,
        filterOn: [],
        accountsList: [],
        businessUnitList: [],
        expenseCategoryList: [],
        expenseSubCategoryList: [],
        paymentModeList: [],
        locationList: [],
        gstTDSOptions: [
            { title: "GST" },
            { title: "TDS" },
            { title: "NONE" },
            { title: "BOTH" }
        ],
        dateFieldOptions: [
          { text: 'Expense Date', value: 'expense' },
          { text: 'Expense Due Date', value: 'expense-due' }
        ],
        searchFilters: {
            accountSelected: '',
            categorySelected: '',
            subCategorySelected: '',
            paymentModeSelected: '',
            businessUnitSelected: '',
            locationSelected: '',
            filter: '',
            dateFieldSelected: ''
        }
      }
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BSpinner,
        BTable,
        BBadge,
        BPagination,
        AppCollapse,
        AppCollapseItem,
        BCardHeader, BCardText, BCardTitle, BCardBody, BMedia, BMediaAside, BMediaBody, BAvatar,
        BInputGroup, BFormInput, BInputGroupAppend, BFormSelect, BFormGroup, BFormRadioGroup,
        vSelect, flatPickr
        //BMedia, BMediaAside, BAvatar, BMediaBody
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  computed: {
    filteredSubCategories() {
            if (!this.searchFilters.categorySelected) return [];
            const prefix = `SUB-CATEGORY - ${this.searchFilters.categorySelected}`;
            console.log('sub category filter: ',this.expenseSubCategoryList.filter(subCat => subCat.param_name === prefix))
            return this.expenseSubCategoryList.filter(subCat => subCat.param_name === prefix);
        }
  },
  watch: {
    searchFilters: {
        handler(newFilters) {
          // Convert query parameters and new filters to strings for easy comparison
          const currentQuery = JSON.stringify(this.$route.query);
          const newQuery = JSON.stringify(newFilters);

          // Only navigate if the queries are different
          if (currentQuery !== newQuery) {
            this.$router.push({ query: newFilters }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
              // Ignore NavigationDuplicated error, but rethrow all other errors
            });
          }
        },
        deep: true,
      },
      $route(to) {
        this.searchFilters = { ...this.searchFilters, ...to.query };
      },
  },
  created() {
    console.log('expense access check: ', this.$can('read','expense'))
    // const userRole = JSON.parse(localStorage.getItem('userData')).roles[0]
    this.empId = JSON.parse(localStorage.getItem('userData')).emp_id
    // const userMgrid = JSON.parse(localStorage.getItem('userData')).mgr_id
    const userData = JSON.parse(localStorage.getItem('userData'))
    // this.accessCheck = userRole === 'ACCOUNT-MANAGER' || userRole === 'TALENT-ADVISOR' || userRole === 'TALENT-ADVISOR-SOURCER' ? false : true
    // console.log(this.accessCheck)
    // this.role = userData.roles[0].toLowerCase()
    this.name = userData.first_name + ' ' + userData.last_name
    if(!this.$can('read','expense') ) {
        this.$router.push({ name: "misc-not-authorized" });
    }
    this.allUsers = JSON.parse(localStorage.getItem('allusers'))
    this.loggedInUser = {
            title: this.name,
            empId: this.empId,
            email: userData.email
    }
    // this.expense.approvedBy = {
    //     title: this.name,
    //     empId: this.empId,
    //     email: userData.email
    // }

  },
  methods: {
    approveExpense(id) {
        const data = {
            is_approved: 'APPROVED',
            approved_by: JSON.stringify(this.loggedInUser),
            //approved_on: new Date().toJSON().slice(0,10).replace(/-/g,'/')
            approved_on: new Date()
        }
        expenseService.updateExpense(id,data).then(res => {
            console.log(res)
            if(res.status === "OK") {
                this.getExpensesList()
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Approved!!!',
                        icon: 'EditIcon',
                        variant: 'primary',
                        timeout: 10000,
                      },
                })
            } else {
                this.getExpensesList()
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Approve Failed!!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 10000,
                      },
                })
            }
        })
    },
    rejectExpense(id) {
        const data = {
            is_approved: 'REJECTED',
            approved_by: JSON.stringify(this.loggedInUser),
            //approved_on: new Date().toJSON().slice(0,10).replace(/-/g,'/')
            approved_on: new Date()
        }
        expenseService.updateExpense(id,data).then(res => {
            console.log(res)
            if(res.status === "OK") {
                this.getExpensesList()
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Rejected!!!',
                        icon: 'EditIcon',
                        variant: 'primary',
                        timeout: 10000,
                      },
                })
            } else {
                this.getExpensesList()
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Expense Approve Failed!!!',
                        icon: 'EditIcon',
                        variant: 'danger',
                        timeout: 10000,
                      },
                })
            }
        })
    },
    getExpensesList() {
        const account = this.searchFilters.accountSelected ? this.searchFilters.accountSelected : ''
        const business_unit = this.searchFilters.businessUnitSelected ? this.searchFilters.businessUnitSelected : ''
        const category = this.searchFilters.categorySelected ? this.searchFilters.categorySelected : ''
        const sub_category = this.searchFilters.subCategorySelected ? this.searchFilters.subCategorySelected : ''
        const dateFilterSelected = this.searchFilters.dateFieldSelected ? this.searchFilters.dateFieldSelected : ''
        const start_date = this.searchFilters.startDate ? this.searchFilters.startDate : ''
        const end_date = this.searchFilters.endDate ? this.searchFilters.endDate : ''
        const gst_tds = this.searchFilters.gstTDSSelected ? this.searchFilters.gstTDSSelected : ''
        const location = this.searchFilters.locationSelected ? this.searchFilters.locationSelected : ''
        const payment_mode = this.searchFilters.paymentModeSelected ? this.searchFilters.paymentModeSelected : ''

        if(start_date && end_date && !dateFilterSelected) {
            window.alert("please select date type expense date or expense due date!!!")
        }

        expenseService.getAllExpenses(account, business_unit, category, sub_category, dateFilterSelected, start_date, end_date, gst_tds, location, payment_mode).then(res =>{
            if(res.status === "OK") {
                this.expenseList = res.data
                console.log(res)
                this.totalRows = this.expenseList.length
                this.expenseListPendingApproval = this.expenseList.filter(item => item.is_approved === 'REJECTED' || item.is_approved === null )

                this.expense = {
                    totalAmount: this.expenseList.reduce((acc, cur) => acc + parseFloat(cur.amount || 0), 0),
                    totalGST: this.expenseList.reduce((acc, cur) => acc + parseFloat(cur.gst_amount || 0), 0),
                    totalTDS: this.expenseList.reduce((acc, cur) => acc + parseFloat(cur.tds_amount || 0), 0),
                    pendingApproval: this.expenseListPendingApproval.length
                }
            }
        })
    },
    getEmpName(emp_id) {
        return this.allUsers.filter(item => item.emp_id === emp_id)[0]
        
    },
    onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
    },
    // getFilteredExpenseList() {

    // },
    clearFilter() {
        this.searchFilters = {
            accountSelected: '',
            categorySelected: '',
            subCategorySelected: '',
            paymentModeSelected: '',
            businessUnitSelected: '',
            locationSelected: '',
            filter: '',
            dateFieldSelected: ''
        }
    }
  },
  updated: function () {
    //this.getExpensesList()
      console.log('called in updated')
      this.$nextTick(function () {
        console.log('called in updated nextTick')
        // Code that will run only after the
        // entire view has been re-rendered
        // let y = null
        const scrollPosition = localStorage.getItem('lastPosition')
        console.log('called in updated nextTick getting localstorage', typeof scrollPosition, scrollPosition)
        if (scrollPosition !== '[object Object]') {
           const y = JSON.parse(scrollPosition).y
           

          setTimeout( () => {
            console.log('called in updated nextTick after settimeout', scrollPosition, y)
            if(y) {
              window.scrollTo(0, y);
            }
          },500);
        }
        
        
      })
  },
  mounted() {
    clientService.getClients().then(res => {
            this.accountsList = res.data
            console.log(this.accountsList)
        })
    AdminConfigService.getConfigExpense().then(res => {
            if (res.status === 'OK'){
                console.log('expense config list: ', res.data)
                this.businessUnitList = res.data.businessUnit;
                this.expenseCategoryList = res.data.category;
                this.expenseSubCategoryList = res.data.sub_category;
                this.paymentModeList = res.data.payment_mode
            }
    })
    locationService.getAll().then(res => {
            console.log(res.data)
            this.locationList = res.data
    })
    this.getExpensesList()
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
.demo-inline-spacing {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>